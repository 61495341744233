import React from 'react';
import styled from 'styled-components';
import qs from 'qs';

import Column from '../components/column';
import Container from '../components/container';
import Cta from '../components/cta';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';
import Icon from '../components/icon';

import logo from '../assets/icons/logo.svg';

const HeadSection = styled(Section)`
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};

  svg {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
`;

export default ({ location }) => {
  const { orderId, status } = qs.parse(location.search.replace('?', ''));
  return (
    <Layout title="Status płatności">
      <HeadSection>
        <Container>
          <Row>
            <Column>
              <Icon icon={logo} fill="#ffffff" />
            </Column>
          </Row>
        </Container>
      </HeadSection>
      <Section>
        <Container>
          <Row>
            <Column style={{ textAlign: "center"}}>
              <Heading as="h1">Status płatności</Heading>
              {
                status === "ok" && 
                <p>{`Twoje zamówienie o numerze ${orderId} zostało opłacone!`}</p>
              }
              {
                status !== "ok" &&
                <>
                  <p>{`Wystąpił problem z opłaceniem Twojego zamówienia o numerze ${orderId}.`}</p>
                  <p>Spróbuj jeszcze raz!</p>
                </>
              }
              <Cta to="/">Wróc do sklepu</Cta>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout> 
  );
};
